@import "variables";

.autoComplete_wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 2px;
}

.autoComplete_wrapper > input {
  width: 100%;
  height: 32px;
  padding-left: 2.2rem;
  font-size: 12px;
  color: $dark-blue;
  border: 1px solid $dark-blue;
  outline: none;

  background-image: url(./assets/search.svg);
  background-size: 0.85rem;
  background-position: left 0.6rem top 0.6rem;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-color: #fff;
}

.autoComplete_wrapper > input::placeholder {
  color: $dark-blue;
  transition: all 0.3s ease;
}

.autoComplete_wrapper > ul {
  position: absolute;
  max-height: 226px;
  overflow-y: scroll;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0.2rem 0 0 0;
  background-color: #fff;
  border: 1px solid $dark-blue;
  z-index: 1000;
  outline: none;

  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $transparent-dark-blue; /* color of the scroll thumb */
    border-radius: 50px; /* roundness of the scroll thumb */
  }
}

.autoComplete_wrapper > ul > li {
  padding: 10px 20px;
  list-style: none;
  text-align: left;
  font-size: 12px;
  font-family: arial;

  color: #212121;
  transition: all 0.1s ease-in-out;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
}

.autoComplete_wrapper > ul > li::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_wrapper > ul > li:hover {
  cursor: pointer;
  background-color: rgba(123, 123, 123, 0.1);
}

.autoComplete_wrapper > ul > li mark {
  background-color: transparent;
  color: $dark-blue;
  font-weight: bold;
}

.autoComplete_wrapper > ul > li mark::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_wrapper > ul > li[aria-selected="true"] {
  background-color: rgba(123, 123, 123, 0.1);
}

.no_result {
  margin: 16px;
}

@media only screen and (max-width: 600px) {
  .autoComplete_wrapper > input {
    width: 18rem;
  }
}
