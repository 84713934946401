$padding: 20px;
$margin: 20px;

// Social Challenge
$primary-color: #0075c9;

// Companies
$secondary-color: #0396ff;

// Expertise
$tertiary-color: #96d9ff;

// links
$quaternary-color: #14c2f4;

$dark-blue: #0a1f54;

$transparent-dark-blue: #0a1f5480;

$selected-blue: #003fb6;

$theme-green: #39c643;

$theme-red: #e40613;

$theme-light-blue: #6dc3e8;

$accent-color: $theme-green;

// Border radius for labels
$border-radius: 6px;

// Set global breaking point
$break-point: 450px;
